.contain::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 40px;
    height: 90px;
    width: 100%;
    background-color: rgb(28, 33, 35);
    z-index: -1;
}

.news-container{
    position: relative;
    padding: 10px;
    background-color : #fff;
    min-height: 100vh;
}

.news-container .news-header{
    text-align: center;
    font-size: 2rem;
    color: #2d545e;
}
.news-container .new-caption{
    text-align: center;
    font-size: 1.5rem;

}
.new{    
    display: block;
    border-bottom: 1px solid #ddd;
    width: 100%;
    height: fit-content;
    padding: 10px;
}
.date{
    color: #333;
    font-size: .7rem;
}

.row{
    display: block;
    margin-top: 10px;
}
.new .col-1{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    
}
.new .col-1 img{
    height: 200px;    
}

.new .col-2{
    position: relative;
    height: fit-content;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: justify;
}
.date{
    color: #333;
    font-size: .9rem;
    display: flex;
    flex-direction: row-reverse;

}
.new .new-button{
    margin-left: 10px;
    background-color: #f1f1f1;
    width: 80px;
    padding: 3px;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    font-size: .9rem;
    color: #333;
    
}
@media only screen and (max-width: 650px) {
    .contain::before{
        height: 190px;
    }
    .news-container{
    margin-top: 60px;
    }
    .new{    
        height: fit-content;
        padding: 10px;
    }
    .new h2{
        font-size: 1.1rem;
    }
    .date{
        color: #333;
        display: flex;
        flex-direction: row-reverse;
    }
    .row{
        display: block;
        height: fit-content;
        
    }

    .new .col-1 {
        margin-top: 10px;
        margin-bottom: 15px;
    }        
    .new .col-2 {
        padding-left: 10px;
        padding-right: 10px;
}        
}
