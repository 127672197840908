.franchise-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 40px;
    height: 90px;
    width: 100%;
    background-color: rgb(28, 33, 35);
    z-index: -1;
}
.franchise-container{
    display: block;
    min-height:100vh;
    padding: 10px;
    margin-top: 100px;
}
.franchise-header{
    text-align: center;
    color: #0c313d;
    margin-bottom: 10px;
}

.franchise-container .inputs{
    display: flex;
    width: 100%;
    justify-content: center;

}

.franchise-container form input{
    display: block;
    margin: 10px;
    padding: 5px;
    width: 350px;

}
.franchise-container form input::placeholder{
    text-align: center;
    font-weight: 600;
}
.franchise-container form textarea{
    display: block;
    margin: 10px;
    resize: none;
    padding: 5px;
    width: 350px;
    height: 100px;
    
}
.franchise-container form textarea::placeholder{
    text-align: center;
    font-weight: 600;
}
.franchise-container form button{
    padding: 4px;
    background-color: #0c313d;
    color: #f1f1f1;
    
}
.franchise-container form .button{
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 650px) {
    .franchise-container::before{
        height: 180px;
    }
    .franchise-container{
        margin-top: 130px;
    }
    .franchise-container form input{
        width: 250px;
        
    }
    .franchise-container form textarea{
        width: 250px;
        
    }
}
