.news-detail-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 40px;
    height: 90px;
    width: 100%;
    background-color: rgb(28, 33, 35);
    z-index: -1;

}
.news-detail-container{
    padding: 10px;
    display: block;
    min-height: 100vh;
    width: 100%;
    background-color : #fff;
}
.news-detail-container h2{
    text-align: center;
    font-size: 2rem;
}
.news-detail-container p{
    font-size: 1.2rem;
    margin: 10px 0;
}

.news-date{
    display: flex;
    flex-direction: row-reverse;
    color: #333;
    font-size: .7rem;
    
}
.news-detail-container .file-box{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}
.news-detail-container .file-box img{
    height: 380px;    
}
.news-detail-container .news-content{
    display: block;
    margin: 10px 20px;
}
@media only screen and (max-width: 650px) {
    .news-detail-container{
        margin-top: 110px;
    }
    .news-detail-container::before{
        height: 190px;
    }    
    .news-detail-container .file-box img{
        height: 220px;
        width: 100%;

    }

}
