.products-header{
    text-align: center;
    margin-top: 20px;
    font-size: 2rem;
    color: #0c313d;
}
.products-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 40px;
    height: 90px;
    width: 100%;
    background-color: rgb(28, 33, 35);
    z-index: -1;
}
.products-container{
    display: block;
    justify-content: center;
    padding: 20px;
    background-color:#fff ;
}
.products-container .product{
    position: relative;
    display: inline-block;
    width: 450px;
    margin-top: 20px;
    margin-left: 20px;
    border: 1px solid black;
    border-radius: 10px;
    padding:10px;
} 

.products-container .product .products-text{
    font-size: 1.3rem;
    margin-top: 10px;
    padding: 5px;
}
.products-container .product h2{
    font-size: 1.4rem;
    text-align: center;
    margin: 10px 0px;
}
.products-container .product img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.products-container .product a{
    text-decoration: none;
    font-size: 1.3rem;
    color: blue;
    margin-left: 5px;
    display: block;
    text-align: center;
}
.products-container .product a:hover{
    color: rgb(196, 216, 44);
    font-weight: 800;
}
.deamand-create{
    position: relative;
}
.deamand-create h3{
    text-align: center;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}
.deamand-create input{
    text-align: center;
    margin-left: 8px;
    padding: 2px;    
    margin-bottom: 4px;
}
.deamand-create input::placeholder{
    font-size: .8rem;
    
}
.deamand-create textarea{
    width: 350px;
    resize: none;
    margin-left: 8px;
    padding: 3px;
}
.deamand-create textarea::placeholder{
    font-size: .9rem;
    text-align: center;
    line-height: 25px;
}
.deamand-create .inputs{
    display: flex;
    justify-content: center;
    width: 100%;
}
.deamand-create .button{
    display: flex;
    justify-content: center;

}

.deamand-create .button button{
    margin-top: 5px;
    padding: 3px;
    background-color: black;
    color: #f1f1f1;
    font-size: 1rem;
}

@media only screen and (max-width: 650px) {
    .products-container .product{
        width: 91%;
    }
    .products-container .product img{
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
    .products-container{
        padding: 0px;
        margin-top: 100px;
        padding-bottom: 20px;

    }   
    .products-container .products-header{
        
        padding-top: 30px;

    }   
    .products-container::before{
        height: 190px;
    
    }
.deamand-create .inputs{
    display: block;
    width: 100%;
}
.deamand-create .inputs input{
    width:98%;
}
.deamand-create textarea{
    width:98%;
}
}
/* 
background-color: rgb(28, 33, 35);
background-color: rgb(87, 185, 87 );

*/