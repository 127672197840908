.side-bar{
    position: fixed;
    right: 20px;
    bottom: 50px;
    z-index: 2;
    
}
.side-bar img{
    width: 50px;
    margin-right: 5px;

}
.container{
    display: block;
    background:url('./lok.jpg');
    background-size: cover;
    height: 500px;
    width: 100%;
    position: absolute;
    top: 40px;
    z-index: -1;
}
.container::before{
    content: '';
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: .5;
    position: absolute;
}

.banner-text{
    position: absolute;
    top: 400px;
    left: 350px;
    font-size: 3rem;
    transition: .5s ease-in;
    color: #f1f1f1;
}
.banner-text p {
    padding: 5px;
    
}
.banner-text p a:hover{
    padding: 5px;
    background-color: rgb(196, 216, 44);

}
.banner-text p a{
    text-decoration: none;
    color: #f1f1f1;
    font-size: 2rem;

}
.brand{
    display: block;
    position: relative;
    text-align: center;
    top: 10px;
    font-size: 2rem;
    border-bottom: 2px solid rgb(196, 216, 44);
    color: rgb(196, 216, 44);

}

.products-row{
    display: block;
    position: relative;
    top: 600px;

}
.news-row{
    display: block;
    position: relative;
    top: 630px;

}

.products{
    display: block;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
    top: 600px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
     
    /* background-color: #feda6a; */

}
.products h2{
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
    color: #0c313d;
}
.products .product h2 span{
    font-size: 1rem;    
    
}
.products .product h2,h3,p {
    text-align: center;
    margin:15px 0;    
}
.products .product h3 {
    margin-bottom: 10px;
    
}
.products .product .icons {
    display: flex;
    justify-content: space-around;
}
.products .product .icons img {
    width: 40px;
    height: 40px;    
}
.product-image{
    width: 100%;
    height: 230px;
}
.products .product{
    position: relative;
    display: inline-block;
    width: 350px;
    padding: 10px;
    margin-left: 20px;
    margin-top: 5px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
  

.footer{
    display: block;
    position: relative;
    top: 640px;
    min-height: 20vh;

}
.footer h2{
    text-align: center;
}
.footer p{
    text-align: center;
}
@media only screen and (max-width: 650px) {
    .brand{
        margin-top: 40px;
    
    }
    .products-row{
        top: 560px;
    }   
    .news-row{
        display: block;
        position: relative;
        top: 560px;
    }
    
}

/* background-color: #4ABDAC;
 */    

/* 


.brand-content{
    height: fit-content;
    width: 100%;
    padding-bottom: 100px;
    padding-top: 100px;
    margin-bottom: 20px;
}
.brand-text{
    display: block;
    color: #333;
    font-size: 1.4rem;
    text-align: center;
    margin: 10px 0;
}
.brand-image{
    display: block;
    width: 100%;
    height: 300px;    
}
.brand-image img{
    display: block;
    width: 100%;
    height: 300px; 
    object-fit: cover;   
}

.products{
    display: block;
    margin-top: 20px;
}
.products h2{
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
}
.products .product h2 span{
    font-size: 1rem;    
    
}
.products .product h2,h3,p {
    text-align: center;
    margin:15px 0;    
}
.products .product h3 {
    color: #f00;
    margin-bottom: 10px;
    
}
.products .product .icons {
    display: flex;
    justify-content: space-around;
}
.products .product .icons img {
    width: 40px;
    height: 40px;    
}
.product-image{
    width: 100%;
    height: 230px;
}
.products .product{
    position: relative;
    display: inline-block;
    width: 350px;
    padding: 10px;
    margin-left: 20px;
    margin-top: 5px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;}

  


.content{
    width: 100%;
    height: 300px;
    display: block;
}
.content .content-image{
    width: 330px;
    float: left;
    margin-right: 20px;
    margin-left: 20px;

}
.content .content-image img{
    width: 100%;

}
.content .content-text{
    width: 100%;

}
.content .content-text p{
    text-align: justify;
    margin-right: 15px;
    margin-left: 15px;
}
 */@media only screen and (max-width: 850px) {
    .banner-text{
        top: 300px;
        left: 100px;
        font-size: 1.8rem;
    }
        
}


@media only screen and (max-width: 550px) {
    .products .product{
        width: 90%;
    
    }
    .content{
        height: 480px;
    }
    .banner-text{
        top: 300px;
        left: 40px;
        font-size: 1.2rem;

    }
        
}
