.product-container::before{
    content: '';
    position: absolute;
    top: 0;
    margin-top: 40px;
    height: 90px;
    width: 100%;
    background-color: rgb(28, 33, 35);
    z-index: -1;
}
.product-container{
    display: block;
    min-height: 100vh;
    width: 100%;
    background-color : #fff;

}
.product-container h2{
    text-align: center;
    font-size: 2rem;
    margin: 10px 0;
    color: #0c313d;
    padding-top: 30px;
}

.product-container .image-box{
    display: block;
    height: 350px;
}
.product-container .image-box img{
    display: block;
    height: 100%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;    

}
.product-container .product-content{
    margin: 10px 20px;
    display: block;
}

.product-container .product-content h3{
    font-size: 1.5rem;
    margin: 10px 0;
    color: #0c313d;
    
}
.product-container .product-content p{
    font-size: 1.2rem;
    margin: 10px 0px;
    color: #000;

}
@media only screen and (max-width: 650px) {
    .product-container .image-box img{
        width: 100%;
    }
    .product-container{
    margin-top: 100px;
    }    
    .product-container::before{
    height: 190px;

}
}
