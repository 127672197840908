.lds-roller {
    display: inline-block;
    position: absolute;
    top: 250px;
    left:50%;
        
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  


.register-container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
  }
  .register-form {
    width: calc(100% - 20px);
    max-width: 500px;
    background-color: #fff;
    padding: 50px 40px;
  }
  
  .register-form h1 {
    text-align: center;
    margin: 40px 0;
    font-size: 2rem;
    color: #64dcd4;
  }
  .register-form .textbox{
    width: 300px;
    padding: 5px;
    font-size: 1rem;
    
}
  .register-form .textbox input{
    padding: 5px;
    width: 100%;

}
  
  .register-form .selectbox{
    position: relative;
    width: 280px;
    display: inline-block;
    margin-left: 5px;
  }
  
  .register-form .selectbox select{
    width: 50%;
    padding: 5px;
    font-size: 1rem;
    padding: 5px;

}
  
  .register-form button {
    display: block;
    width: fit-content;
    height: 40px;
    padding: 5px;
    margin: 40px auto;
    background-color: #64dcd4;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.3s linear;
  }
  
  button:hover {
    opacity: 0.85;
  }
  
  @media only screen and (max-width: 600px) {
  
  .register-form .selectbox select{
    width: 50%;
    padding: 5px;
    font-size: .7rem;
  }
  }
  