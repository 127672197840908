/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}
nav{
    height: 80px;
    display: flex;
    justify-content:space-between;
    
}

.social{
    display: flex;
    position: relative;
    height: 40px;
    width: 100%;
    justify-content: space-between;    
}
.social ul{
    position: relative;
    margin-right: 10px;
    
}
.social ul li{
    list-style-type: none;
    display: inline-block;
    margin:5px 10px;
}
.social ul li a{
    text-decoration: none;
    color:#333
}
.social ul li img{
    height: 30px;
    width: 30px;
}

.logo{
    width: 100px;
    height: 100%;
    text-align: center;
}
.logo span{
    color: #333;
    line-height: 60px;
    font-size: 1.2rem;
    font-weight: 700;

}
.logo span img{
    height: 80px;
}
.menu{
    width: 465px;
    height: 100%;
    text-align: center;
    margin-right: 5px;
}

.link{
    display: inline-block;
    list-style-type: none;
    line-height: 80px;
    margin-left: 30px;
}
.link a{
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 800;
    color: #fff;
}
.link a:hover{
    font-size: 1.3rem;
    border-bottom: 2px solid #f1f1f1;
}

@media only screen and (max-width: 650px) {
    nav{
        display: block;
    }
    .logo {
        display: block;
        position: relative;
        width: 100%;
    }
    .menu{
        display: block;
        width: 100%;
        position: relative;

    }        
}
@media only screen and (max-width: 450px) {

    .link{
        margin-left: 12px;
        display: flex;
        justify-content: space-around;
    }
    .link a{
        font-size: 1rem;

    }        

}